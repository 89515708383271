import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';
import ReactPlayer from 'react-player';

import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  // header: {
  //   color: '#002d5c',
  //   textAlign: 'center',
  //   [theme.breakpoints.down('sm')]: {
  //     textAlign: 'left',
  //   },
  // },
  pinkHighlight: {
    color: '#D10066',
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',
    minHeight: '100%',
    minWidth: '100%',
    border: '5px white solid',
    boxShadow:
      '0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3)',
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

export const OverviewFeature = ({
  overviewHeader,
  overviewBody,
  overviewImage,
  overviewVideo,
}) => {
  const classes = useStyles();
  const md = useMediaQuery('(max-width: 960px)');
  const sm = useMediaQuery('(max-width:600px)');
  return (
    <Grid
      container
      direction='row'
      justifyContent='space-evenly'
      spacing={1}
      style={{ paddingBottom: '4rem' }}
    >
      <Grid
        item
        container
        direction='row'
        alignItems='center'
        justifyContent='center'
        style={{ marginBottom: sm && '2rem', marginTop: md && '2rem' }}
        xs={12}
        md={5}
      >
        {overviewVideo ? (
          <div className={classes.playerWrapper}>
            <ReactPlayer
              url={overviewVideo}
              className={classes.reactPlayer}
              height='100%'
              width='100%'
              controls={true}
            />
          </div>
        ) : (
          <GatsbyImage image={overviewImage?.asset?.gatsbyImageData} />
        )}
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={6}
        spacing={2}
        direction='column'
        justifyContent='center'
        alignItems='flex-start'
      >
        <Grid item>
          <Typography
            variant='h2'
            color='primary'
            style={{
              lineHeight: 1.4,
            }}
          >
            {overviewHeader}
          </Typography>
        </Grid>
        <Grid item>
          {overviewBody?.map((content, index) => (
            <PortableText
              key={index}
              content={content}
              serializers={{
                h2: ({ children }) => (
                  <Typography
                    variant='h2'
                    color='primary'
                    className={classes.header}
                  >
                    {children}
                  </Typography>
                ),
                strong: ({ children }) => (
                  <span className={classes.pinkHighlight}>{children}</span>
                ),
                normal: ({ children }) => (
                  <Typography
                    variant='body1'
                    style={{
                      color: '#4B5B69',
                      lineHeight: 1.7,
                    }}
                  >
                    {children}
                  </Typography>
                ),
              }}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
