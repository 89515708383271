import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import { GeneralHero } from '../components/General/GeneralHero';
import { IndustryFAQ } from '../components/Industry/IndustryFAQ';
import { Resources } from '../components/General/Resources';
import { SectionBlue } from '../components/Sections/SectionBlue';
import { SectionWhite } from '../components/Sections/SectionWhite';
import { SectionLight } from '../components/Sections/SectionLight';
import { HeroWaveTwo } from '../components/Waves/HeroWaveTwo';
import { SectionWaveDefault } from '../components/Waves/SectionWaveDefault';
import { Related } from '../components/Industry/Related';
import { SEO } from '../components/SEO';
import { SoftwareFeatureGroups } from '../components/Industry/SoftwareFeatureGroups';
import { IndustryBody } from '../components/Industry/IndustryBody';
import { ThumbnailFeatureModal } from '../components/General/ThumbnailFeatureModal';
import FeatureThumbnailContext from '../context/FeatureThumbnailContext';
import LocaleContext from '../context/LocaleContext';
import Form from '../components/ContactForm/Form';

const useStyles = makeStyles((theme) => ({
  background: {
    background: theme.white,
  },
  relatedBackground: {
    marginBottom: '7rem',
    [theme.breakpoints.down('md')]: {
      marginBottom: '4rem',
    },
  },
  formWaveDown: {
    marginBottom: '-8rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-4rem',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-3rem',
    },
  },
  formWaveUp: {
    marginBottom: '-8rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-10rem',
    },
  },
  formCont: {
    padding: '10rem 0 24rem',
    [theme.breakpoints.down('md')]: {
      padding: '10rem 0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '8rem 0',
    },
  },
}));

export default function Industry({ data: { industry }, location }) {
  const { handleRedirect, ipData } = useContext(LocaleContext);

  const { modalOpen, setModalOpen, selectedThumbnail } = useContext(
    FeatureThumbnailContext
  );

  //state for selected card for features
  const [selectedFeature, setSelectedFeature] = useState(null);

  useEffect(() => {
    setSelectedFeature(industry.featureSection[0]?._key);
  }, []);

  //feature list modal controls
  const [featureModalOpen, setFeatureModalOpen] = useState(false);

  const {
    metaTitle,
    metaDescription,
    pardotUrl,
    contactForm,
    hero,
    relatedIndustries,
    relatedHeader,
    softwareFeaturesHeader,
    softwareFeaturesSubheader,
    softwareFeatures,
    softwareFeatureCta,
    faq,
    faqTitle,
    resourceTitle,
    resources,
    _rawResourceBody,
  } = industry;

  useEffect(() => {
    ipData && handleRedirect(location.pathname, true);
  }, [ipData]);

  if (!hero) return <div>no hero here</div>;

  return (
    <>
      <SEO title={metaTitle} description={metaDescription} />
      <SectionBlue top extraBottom>
        <GeneralHero heroData={hero} />
        <HeroWaveTwo />
      </SectionBlue>
      <IndustryBody
        industry={industry}
        setFeatureModalOpen={setFeatureModalOpen}
      />

      <SectionWhite top extraBottom>
        <SoftwareFeatureGroups
          softwareFeatures={softwareFeatures}
          header={softwareFeaturesHeader}
          subheader={softwareFeaturesSubheader}
          cta={softwareFeatureCta}
        />
        <SectionWaveDefault
          flipHorizontal={false}
          flipVertical={true}
          bottom='0px'
          fillColorShape='#F5F9FF'
          fillColorBorder='#F5F9FF'
        />
      </SectionWhite>

      <SectionLight>
        <Form
          pardotUrl={pardotUrl}
          contactForm={contactForm}
          location={location}
        />
      </SectionLight>

      <SectionWhite extraTop>
        <SectionWaveDefault
          flipHorizontal={true}
          top='0px'
          fillColorBorder='#F5F9FF'
        />
        <Container>
          <IndustryFAQ header={faqTitle} faq={faq} />
        </Container>
      </SectionWhite>

      <SectionLight extraBottom extraTop>
        <SectionWaveDefault
          flipHorizontal={true}
          top='0px'
          fillColorBorder='#FFF'
          fillColorShape='#FFF'
        />
        <Container>
          <Related related={relatedIndustries} header={relatedHeader} />
        </Container>
        <SectionWaveDefault
          flipHorizontal={true}
          flipVertical={true}
          bottom='0px'
          fillColorBorder='#FFF'
          fillColorShape='#FFF'
        />
      </SectionLight>

      {/*
			<FeatureModal
				header={supportedProductsHeader}
				subheader={supportedProductsSubheader}
				softwareFeatures={softwareFeatures}
				open={featureModalOpen}
				setFeatureModalOpen={setFeatureModalOpen}
				accentColor={accentColor[0].hexValue}
			/> */}
      <SectionWhite zIndex={3}>
        <Resources
          header={resourceTitle}
          subheader={_rawResourceBody}
          resources={resources}
        />
      </SectionWhite>

      <ThumbnailFeatureModal
        open={modalOpen}
        setFeatureModalOpen={setModalOpen}
        wistiaLink={selectedThumbnail}
        feature
      />
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    industry: sanityIndustries(slug: { current: { eq: $slug } }) {
      _id
      title
      metaTitle
      metaDescription
      accentColor {
        hexValue
      }
      leadoo {
        botId
        seamless
      }
      hero {
        _rawContent
        heroImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
        showEmailForm
      }
      overviewHeader
      _rawOverviewBody
      overviewImage {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      overviewVideo
      _rawByTheNumbersHeader
      byTheNumbers {
        number
        unit
        _rawDescription
        icon
      }
      byTheNumbersDisclaimer
      noStatsCta {
        ctaBackground {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaHeader
        _rawCtaBlockHeader
        ctaLink
        ctaSubheader
        ctaText
        internalLink
      }
      # industryCallout {
      # 	ctaBackground {
      # 		asset {
      # 			gatsbyImageData(placeholder: BLURRED)
      # 		}
      # 	}
      # 	ctaHeader
      # 	ctaSubheader
      # 	ctaText
      # 	internalLink
      # 	ctaLink
      # }
      # multiuseCta {
      #	 _rawCtaHeader
      #	 ctaSubheader
      #	 ctaText
      #	 ctaLink
      #	 internalLink
      #	 ctaBackground {
      #		 asset {
      #			 gatsbyImageData(placeholder: BLURRED)
      #		 }
      #	 }
      #	 mobileCtaBackground {
      #		 asset {
      #			 gatsbyImageData(placeholder: BLURRED)
      #		 }
      #	 }
      # }
      featureHeader
      featureSection {
        _key
        title
        cardTitle
        cardFaIcon
        cardIconColor {
          hexValue
        }
        bodySubHeader
        bodyHeader
        bodySubHeader
        specialFaIcon {
          title
          name
          colors {
            color
          }
        }
        image {
          featureImage {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        videoVariant
        # androidLink
        # iosLink
        # qbLink
        ctaText
        # svgVariant
        thumbnailVideoUrl
        _rawBodyCopy
      }
      softwareFeaturesHeader
      softwareFeaturesSubheader
      softwareFeatures {
        softwareFeatureGroups {
          softwareFeatureGroupHeader
          softwareFeatureGroupListItems
        }
        title
      }
      softwareFeatureCta
      faqTitle
      faq {
        question
        _rawAnswer
      }
      testimonialHeader
      testimonials {
        title
        testimonialText
        nameAndTitle
        company
        videoVariant
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 700)
          }
        }
      }
      featuredInHeader
      featuredInLogos {
        featuredInLogoTitle
        featuredInLogo {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 225)
          }
        }
      }
      softwareFeatures {
        title
        softwareFeatureGroups {
          softwareFeatureGroupHeader
          softwareFeatureGroupListItems
        }
      }
      softwareFeatureCta
      relatedHeader
      relatedIndustries {
        faIcon {
          name
          colors {
            color
          }
        }
        accentColor {
          hexValue
        }
        title
        slug {
          current
        }
      }
      resourceTitle
      _rawResourceBody
      resources {
        title
        blurb
        image {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        ctaText
        ctaLink
      }
      formBgImage {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      contactForm {
        header
        privacyPolicy
      }
      pardotUrl
      _rawCustomerLogosHeader
      customerLogos {
        logo {
          asset {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        title
      }
    }
  }
`;
