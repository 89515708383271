import React, { useState, useEffect, useRef } from 'react';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { OverviewFeature } from './Features/OverviewFeature';
import { IntroStats } from '../Home/Intro/IntroStats'; // Updated import path
import { BodyFeatureCard } from './Features/BodyFeatureCard';
import { BodyFeature } from './Features/BodyFeature';
import { Testimonials } from '../Testimonials';
import { MobileFeatures } from './Features/Mobile/MobileFeatures';
import { CustomerLogos } from '../CustomerLogos';
import { SectionWhite } from '../Sections/SectionWhite';
import { SectionBlue } from '../Sections/SectionBlue';
import { SectionWaveDefault } from '../Waves/SectionWaveDefault';

const useStyles = makeStyles((theme) => ({
  testimonialBackground: {
    backgroundSize: 'cover',
    height: '600px',
    width: '99vw',
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      height: '500px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '400px',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundPosition: 'left 60px',
    },
  },
  testimonialContainer: {
    marginTop: '-35rem',
    [theme.breakpoints.down('md')]: {
      marginTop: '-30rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '-25rem',
    },
  },
  testimonialTitle: {
    color: theme.white,
    fontWeight: 700,
    padding: '2rem 0',
  },
}));

export const IndustryBody = ({ industry, setFeatureModalOpen }) => {
  const featureRef = useRef(null);

  // Card array that gets modified on click. Set a fresh array on mount.
  const [cardArray, setCardArray] = useState([]);

  useEffect(() => {
    setCardArray(industry.featureSection);
  }, [industry.featureSection]);

  const setToFirst = (selected) => {
    const filteredArray = cardArray.filter(
      (item) => item._key !== selected._key
    );
    setCardArray([selected, ...filteredArray]);
  };

  // Handles scrolling to selected feature.
  const handleSelectFeature = async (e, content) => {
    await setSelectedFeature(content._key);
    setToFirst(content);
    featureRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  // State for selected feature.
  const [selectedFeature, setSelectedFeature] = useState(null);

  useEffect(() => {
    setSelectedFeature(industry.featureSection[0]?._key);
  }, [industry.featureSection]);

  const med = useMediaQuery('(max-width: 960px)');

  const {
    overviewHeader,
    _rawOverviewBody,
    _rawByTheNumbersHeader,
    byTheNumbers,
    byTheNumbersDisclaimer,
    noStatsCta,
    featureSection,
    featureHeader,
    accentColor,
    softwareFeatures,
    testimonialHeader,
    testimonials,
    customerLogos,
    _rawCustomerLogosHeader,
    overviewImage,
    overviewVideo,
  } = industry;

  // Determine slices for grouping.
  const firstSet = cardArray.length === 4 ? 2 : 3;
  const lastSet =
    cardArray.length === 4
      ? -2
      : cardArray.length === 6
      ? -3
      : cardArray.length === 5
      ? -2
      : null;
  const longPage = industry.featureSection.length > 2;
  const showStatsBar = byTheNumbers.length > 0 || !!noStatsCta;

  const video = testimonials.length ? testimonials[0]?.videoVariant : null;

  return (
    <>
      <Container>
        <OverviewFeature
          overviewHeader={overviewHeader}
          overviewImage={overviewImage}
          overviewBody={_rawOverviewBody}
          overviewVideo={overviewVideo}
        />
        {/* Optionally, add an industry callout CtaCard if needed */}
      </Container>
      {showStatsBar && (
        <SectionBlue
          additionalTop={!noStatsCta && 80}
          bottom
          additionalBottom={!noStatsCta && 80}
        >
          <IntroStats
            header={_rawByTheNumbersHeader}
            stats={byTheNumbers}
            disclaimer={byTheNumbersDisclaimer}
            noStatsCta={noStatsCta}
          />
          <SectionWaveDefault
            flipHorizontal={true}
            flipVertical={true}
            bottom='-1px'
            fillColorShape='#FFF'
          />
        </SectionBlue>
      )}
      <Container>
        {med ? (
          <MobileFeatures
            featureSection={featureSection}
            featureHeader={featureHeader}
            featureRef={featureRef}
            selectedFeature={selectedFeature}
            accentColor={accentColor[0].hexValue ?? '#002D5C'}
            featureListCta={softwareFeatures?.featureListCta}
            setFeatureModalOpen={setFeatureModalOpen}
          />
        ) : (
          longPage && (
            <BodyFeatureCard
              featureHeader={featureHeader}
              featureSection={featureSection}
              selectedFeature={selectedFeature}
              handleClick={handleSelectFeature}
              featureRef={featureRef}
              featureListCta={softwareFeatures?.featureListCta}
              setFeatureModalOpen={setFeatureModalOpen}
              accentColor={accentColor[0].hexValue ?? '#002D5C'}
              // Remove bottom padding for this section (before the testimonial)
              noPaddingBottom={true}
            />
          )
        )}
      </Container>
      <Container>
        {!med &&
          cardArray.slice(0, firstSet).map((feature, index, array) => (
            <BodyFeature
              feature={feature}
              index={index}
              key={feature._key || index}
              selectedFeature={selectedFeature}
              accentColor={accentColor[0].hexValue}
              setSelectedFeature={setSelectedFeature}
              // Remove bottom padding on the last item in this group (before testimonial)
              noPaddingBottom={index === array.length - 1}
            />
          ))}
      </Container>
      {testimonials.length ? (
        <SectionBlue
          extraTop={!video}
          top={video}
          additionalTop={video && med ? 60 : video ? 80 : null}
          marginBottom='-220px'
        >
          <SectionWaveDefault top='0px' fillColorShape='#fff' />
          <Testimonials
            testimonials={testimonials}
            header={testimonialHeader}
            background
          />
        </SectionBlue>
      ) : null}
      <SectionWhite
        top
        additionalTop={testimonials.length ? 220 : 0}
        additionalBottom={120}
      >
        <CustomerLogos
          customerLogosArray={customerLogos}
          customerLogosHeader={_rawCustomerLogosHeader}
        />
      </SectionWhite>
      <Container>
        {!med &&
          cardArray.slice(lastSet).map((feature, index, array) => (
            <BodyFeature
              feature={feature}
              index={index}
              key={feature._key || index}
              selectedFeature={selectedFeature}
              accentColor={accentColor[0].hexValue}
              setSelectedFeature={setSelectedFeature}
              // Remove bottom padding on the last item in this final group
              noPaddingBottom={index === array.length - 1}
            />
          ))}
        {/* {rmSpecialFeature && (
          <RmSpecialSection
            feature={rmSpecialFeature}
            accentColor={accentColor[0].hexValue}
            zapierLogo={zapierLogo}
            shopifyLogo={shopifyLogo}
          />
        )} */}
      </Container>
    </>
  );
};
