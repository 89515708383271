import React, { useState, useRef, useContext } from 'react';

import { Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { FeatureGroups } from './FeatureGroups';
import FormModalContext from '../../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px',
    borderRadius: '20px',
    background: 'transparent',
  },
  title: {
    fontSize: '1.875rem',
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.625rem',
      textAlign: 'left',
    },
  },
  subheader: {
    fontSize: '1.625rem',
    fontWeight: 400,
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem',
      textAlign: 'left',
    },
  },
  sectionHeader: {
    fontWeight: 700,
    color: theme.workwaveBlue,
  },
  sectionSubHeader: {
    color: theme.mediumDarkGray,
  },
  arrow: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    transition: 'transform 0.3s ease',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0.5),
    },
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: '"Poppins", sans-serif',
    fontSize: '1.2rem',
    fontWeight: '600',
    color: '#002d5c',
    justifyContent: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
    // When hovering the link, move the arrow to the right
    '&:hover $arrow': {
      transform: 'translateX(5px)',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      maxWidth: '350px',
    },
  },
}));

export const SoftwareFeatureGroups = ({
  softwareFeatures,
  header,
  subheader,
  cta,
  accentColor,
}) => {
  const classes = useStyles();
  const featureGroupRef = useRef(null);
  const [expanded, setExpanded] = useState(0);

  const { softwareFeatureGroups } = softwareFeatures;

  const { handleModalClick } = useContext(FormModalContext);

  return (
    <Container>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignContent='center'
        spacing={2}
        style={{
          textAlign: 'center',
          paddingTop: '0rem',
          marginBottom: '2rem',
        }}
      >
        <Grid item>
          <Typography variant='h2' className={classes.sectionHeader}>
            {header}
          </Typography>
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography body='body1' className={classes.sectionSubHeader}>
            {subheader}
          </Typography>
        </Grid>
      </Grid>
      {softwareFeatureGroups.map((feature, index) => (
        <FeatureGroups
          featureGroup={feature}
          index={index}
          expanded={expanded}
          setExpanded={setExpanded}
          featureGroupRef={featureGroupRef}
        />
      ))}
      <Grid
        direction='column'
        justifyContent='center'
        alignContent='center'
        spacing={2}
        style={{
          textAlign: 'center',
          paddingTop: '3rem',
        }}
      >
        <Button
          onClick={handleModalClick}
          size='large'
          variant='contained'
          color='primary'
        >
          {cta}
        </Button>
      </Grid>
    </Container>
  );
};
