import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Grid,
  Button,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
  // border: {
  // 	border: `1px solid ${theme.borderLightGray}`,
  // 	borderRadius: '20px !important',
  // 	padding: '1rem',
  // 	marginBottom: '1rem',
  // 	boxShadow: '0 10px 40px 0px rgba(0,0,0,0.10) !important',
  // 	'&:hover': {
  // 		cursor: 'pointer',
  // 	},
  // },
  question: {
    color: theme.workwaveBlue,
    fontWeight: 'bold',
  },
  answer: {
    color: '#5A7184',
    fontWeight: 500,
    // marginTop: '1rem',
    '& a': {
      color: theme.workwaveBlue,
    },
  },
  icon: {
    color: theme.workwaveBlue,
  },
}));

const StyledAccordion = withStyles((theme) => ({
  root: {
    border: '2px solid #E5EAF4',
    borderRadius: '20px !important',
    marginBottom: '1rem',
    boxShadow: '0 10px 10px 0px rgba(0,0,0,0.05) !important',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {
    marginBottom: '1rem!important',
  },
}))(Accordion);

export const FeatureGroups = ({
  featureGroup,
  index,
  expanded,
  setExpanded,
  featureGroupRef,
}) => {
  const { softwareFeatureGroupHeader, softwareFeatureGroupListItems } =
    featureGroup;

  const classes = useStyles();

  const handleChange = (index) => {
    setExpanded(index);
    featureGroupRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <>
      <StyledAccordion
        expanded={expanded === index ? true : false}
        className={classes.border}
        elevation={4}
        onChange={() => handleChange(index)}
      >
        <AccordionSummary
          style={{ padding: '1rem' }}
          expandIcon={
            expanded === index ? (
              <RemoveIcon style={{ color: '#19305A' }} fontSize='large' />
            ) : (
              <AddIcon style={{ color: '#19305A' }} fontSize='large' />
            )
          }
        >
          <Typography variant='h5' className={classes.question}>
            {softwareFeatureGroupHeader}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: '0 1rem 1rem 1rem' }}>
          <Grid container className={classes.answer}>
            {softwareFeatureGroupListItems.map((listItem, index) => (
              <Grid item key={index} xs={12} md={6}>
                <Grid
                  item
                  container
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='center'
                  style={{ flexWrap: 'nowrap' }}
                >
                  <FontAwesomeIcon
                    icon={['fad', 'circle-check']}
                    style={{
                      color: '#63CDE5',
                      height: '50px',
                      width: '50px',
                      padding: '10px',
                      textAlign: 'center',
                    }}
                  />
                  <Typography variant='h6' color='primary'>
                    {listItem}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </StyledAccordion>
    </>
  );
};
