import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  relatedSection: {
    padding: '0',
    zIndex: '2',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 0',
    },
  },
  header: {
    color: theme.workwaveBlue,
    textAlign: 'center',
  },
  subHeader: {
    color: '#5A7184',
    textAlign: 'center',
  },
  link: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    color: theme.workwaveBlue,
    fontFamily: 'Poppins, sans-serif',
    textAlign: 'center',
    lineHeight: '1',
    width: '80%',
  },
  item: {
    padding: '.2rem 0',
  },
  linkCard: {
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.10)',
    borderRadius: '20px',
    border: `2px solid #E5EAF4`,
    cursor: 'pointer',
    background: theme.white,
    width: '90%',
    transition: 'transform 0.25s, box-shadow 0.75s',
    width: '98%',
    '&:hover': {
      transform: 'scale(1.02)',
      transition: 'transform 0.25s, box-shadow 0.75s',
      cursor: 'pointer',
    },
  },
}));

export const Related = ({ related, header, templates }) => {
  const classes = useStyles();
  return (
    <div className={classes.relatedSection}>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignContent='center'
        spacing={2}
        style={{ marginBottom: '2rem' }}
      >
        <Grid item>
          <Typography variant='h2' className={classes.header}>
            {header}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction='row'
        spacing={3}
        justifyContent={related.length < 2 && 'center'}
      >
        {related.map((relate, index) => (
          <Grid
            item
            container
            xs={12}
            sm={6}
            lg={related.length < 3 ? 6 : 4}
            key={index}
          >
            <div className={classes.linkCard}>
              <Link
                to={
                  templates
                    ? `/templates/${relate.slug.current}`
                    : `/${relate.slug.current}`
                }
                style={{ textDecoration: 'none' }}
              >
                <Grid container direction='row' alignItems='center'>
                  <FontAwesomeIcon
                    icon={['fad', relate.faIcon?.name ?? 'fish']}
                    style={{
                      height: '40px',
                      width: '40px',

                      background: '#002D5C',
                      borderRadius: '50%',
                      padding: '6px',
                      margin: '5px',
                      '--fa-primary-color':
                        relate.faIcon?.colors[0]?.color ?? '#AA23FF',
                      '--fa-secondary-color': '#FFF',
                      '--fa-primary-opacity': 1,
                      '--fa-secondary-opacity': 1,
                    }}
                  />
                  <Typography className={classes.link}>
                    {relate.title}
                  </Typography>
                </Grid>
              </Link>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
